import React from 'react'

const BlueLine = ({ width, maxWidth, leftAlign }) => {

    const styles = {
        base: {
            width: width,
            maxWidth: maxWidth,
        }
    }

    return (
        <div className={`blue-line ${leftAlign ? 'blue-line--left-align' : ""}`} style={styles.base}></div>
    )
}

export default BlueLine
