import React from "react"


export const CircleCallOut = ({ children, bgColor, addClass = ''}) => {
  return (
    <aside className={`round ${bgColor} ${addClass}`}>
      <div className="container">
        <p>
          {children}
        </p>
      </div>
    </aside>
  )
}
