import React from "react"

// Component Imports
import BlueLine from "@components/utility/BlueLine.jsx"
import Seo from "@components/utility/Seo"
import Layout from "@components/structure/Layout"
import { CircleCallOut } from "@components/utility/CircleCallout"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const SupportAndSavings = () => {

  const calloutCard = (
    <Row className="d-flex p-sm-4 bg-light-blue align-items-center learn-more-callout--savings
    ">
      <Col md={8} >
        <h3 className="text-white w-100">
          Because of the serious risk of central nervous system depression, abuse, and misuse associated with XYREM, it is available only through enrollment in a restricted program called the XYWAV and XYREM REMS.
        </h3>
      </Col>
      <Col md={4}>
        <a href="https://www.xywavxyremrems.com/" className="button button--orange" data-open-modal="true">Find out more</a>
      </Col>
    </Row>
  )

  return (
    <Layout>
      <Seo
        canonical="/support-and-resources"
        title="Patient Support and Resources | XYREM®"
        description="Learn about the XYREM® (sodium oxybate) support and resources available to adult and pediatric patients through JazzCares® Program. See XYREM® prescribing information & BOXED Warning about serious side effects, CNS depression, misuse and abuse."
      />
      <div className="position-relative container-md support-resources--page">

        <section className="section section--first">
          <div className="row">
            <div className="col-md-12 col-lg-7">
              <h1 className="blueHeading text-center--mobile-only">
                Support all along the way
              </h1>
              <BlueLine width="100%" maxWidth="65px" leftAlign={true} />
              <h3 className="blueSubheading blueSubheading--resources" style={{ marginTop: '1.5625rem' }}>Ongoing information about XYREM</h3>

              <p className="bodyCopy">
                Your healthcare provider is always your first source of
                information about the proper use of XYREM. In addition, JazzCares<sup>&reg;</sup>{" "}
                Nurse Case Managers at the Certified Pharmacy are available as
                another resource for information about XYREM. Pharmacists from the
                Certified Pharmacy are also available to answer your questions
                about XYREM 24 hours a day, 7 days a week.
              </p>
            </div>
            <CircleCallOut
              bgColor="orange"
              addClass="d-block d-md-block d-lg-none text-semibold"
            >
              To reach a JazzCares&nbsp;Nurse Case Manager, <br />call&nbsp;<a className="text-white no-break" href="tel:1-866-997-3688">1-866-997-3688</a>,
              Monday – Friday,<br /> 7 AM – 8 PM, Central Time.
            </CircleCallOut>
            <div className="col-lg-5 d-none d-md-none d-lg-block px-md-4 px-lg-5">
              <CircleCallOut bgColor="orange" addClass="text-semibold">
                To reach a JazzCares&nbsp;Nurse Case Manager, <br />call&nbsp;<a className="text-white no-break" href="tel:1-866-997-3688">1-866-997-3688</a>,
                Monday – Friday,<br /> 7 AM – 8 PM, Central Time.
              </CircleCallOut>
            </div>
          </div>
        </section>


        <section className="section">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <BlueLine width="100%" maxWidth="65px" leftAlign={true} />
              <h3 className="blueSubheading blueSubheading--resources" style={{ marginTop: '1.5625rem' }}>
                Helping make treatment more affordable
              </h3>
              <p className="bodyCopy">
                Jazz Pharmaceuticals is committed to making sure that those who
                may benefit from XYREM can affordably get it. Several different
                options are available for qualified patients based on personal
                needs. A Reimbursement Specialist can explain the details of all
                these programs to you and help determine if one is right for you.
              </p>
              <p>
                <span className="mediumBlueSubheading">INSURANCE AND REIMBURSEMENT SUPPORT</span>{" "}
                <span className="bodyCopy">to help you understand how XYREM fits in your existing insurance
                  coverage; works with a healthcare provider on accessing any
                  necessary paperwork needed.</span>
              </p>
              <p>
                <span className="mediumBlueSubheading">THE XYREM COUPON PROGRAM</span>{" "}
                <span className="bodyCopy">offers a reduction in out-of-pocket expenses to eligible patients.
                  The XYREM Coupon Program can cover monthly out-of-pocket expenses,
                  including co-pays and any plan deductible. Eligible patients can
                  pay no more than $35 dollars per prescription, subject to a
                  maximum annual benefit.</span>
              </p>
              <p className="bodyCopy">
                The XYREM Coupon Program is only available for residents of the US and
                Puerto Rico who have commercial insurance with co-pay/co-insurance
                exceeding $35 per prescription fill. Patients ineligible for the
                JazzCares for XYREM Coupon Program include but are not limited to
                those enrolled in Medicare Part D, Medicaid, Medigap, VA/DoD
                (Tricare) programs, the Indian Health Service, or any other
                federal or state-funded healthcare program, or where prohibited by
                law. The XYREM Coupon Program is not prescription drug coverage or
                insurance. Patients cannot seek reimbursement from health
                insurance or any third party for any part of the benefit received
                by the patient through the XYREM Coupon Program.
              </p>
              <p>
                Jazz Pharmaceuticals reserves the right to terminate or modify
                this program at any time with or without notice.{" "}
                <a
                  href="https://jazzcares.com/xyrem/#termsandconditions"
                  className="blueLink"
                  data-open-modal="true"
                >
                  Other terms and conditions apply
                </a>
                .
              </p>
              <p>
                No enrollment is required. Eligibility is assessed as part of the
                standard benefit investigation process performed by the pharmacy
                Reimbursement Specialist. Any Coupon benefit will be automatically
                deducted from the patient’s co-pay or other out-of-pocket cost
                with a patient’s verbal approval.
              </p>
              <p>
                <span className="mediumBlueSubheading">
                  THE XYREM PATIENT ASSISTANCE PROGRAM (PAP)
                </span>{" "}
                <span className="bodyCopy">
                  is available for those with additional financial needs. If you
                  meet certain financial criteria, such as having a low income
                  level, are uninsured or underinsured, you may be eligible to
                  receive XYREM at no cost.
                </span>
              </p>
            </div>
          </div>
        </section>


        <section className="section">
          <div className="row">
            <div>
              <BlueLine width="100%" maxWidth="65px" />
              <h3 className="blueSubheading" style={{ marginTop: '1.5625rem' }}>Personalized support through JazzCares for XYREM</h3>
              <div className="jazzcares-section">
                <p className="bodyCopy">
                  If your healthcare provider prescribes XYREM to you, know that you will have lots of support along the way. In addition to financial support programs to help you afford treatment, JazzCares for XYREM offers access to information, resources, and programs that can help support you while taking XYREM.
                </p>
                <img
                  src="../../images/jazzcares-logo.png"
                  alt="Jazz Cares logo"
                  className="jazzcares-logo"
                />
              </div>
              <a href="https://jazzcares.com/patients/xyrem/" data-open-modal="true" className="button button--orange jazzcares-link">Learn about JazzCares</a>
              {calloutCard}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default SupportAndSavings
